.main-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

header {
  background-color: #282c34;
  color: white;
  padding: 1rem;
  text-align: center;
}

footer {
  background-color: #282c34;
  color: white;
  padding: 1rem;
  text-align: center;
  margin-top: auto;
}

.content-wrapper {
  display: flex;
  flex: 1;
}

aside {
  background-color: #f4f4f4;
  padding: 1rem;
  width: 200px;
}

main {
  flex: 1;
  padding: 0;
}
