.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.html .body .element {
  overflow-x: hidden;
  margin: 0px;
}

h1 {
  color: rgb(255, 255, 255);
}

p {
  font-size: 18px;
}






.container {
 /*  display: flex;
  flex-wrap: wrap;
  justify-content: center; */
  width: 1000%;
  background-color: rgb(204, 204, 204);
  background-color: rgb(116, 15, 15);
  /* background-image: url(../public/tech2.jpg); */

  
}

row {
 /*  display: flex;
  flex-wrap: wrap; */
  justify-content: center;
  width: 100vw;
  background-color: salmon;
}

.section2 {
  width: 100%;
  height: 100vh;
  background-color: purple;
  background-image: url(../public/tech2.jpg);
  background-size: cover;
  text-align: center;
  color: white;
}






#textbox {
  /* background-color: blue; */
  /* margin: 0vh 21%;	 */
  /* display: flex; */
  /* flex-wrap: wrap; */
  justify-content: center;
}

@media (max-width: 700px) {
  #h1p {
    font-size: 15vw;
    color: white;
  }
  #s1p {
    color: white;
    font-size: 5vw;
  }
}

@media (min-width: 700px) {
  #h1p {
    font-size: 600%;
    color: white;
  }
  #s1p {
    color: white;
    font-size: 2em;
  }
}





:root {
  /* Basis-Margin definieren */
  --basis-margin: 20px;
  --bbreite: 100vw;
}

#textbox {
  margin: 0% 5%;
  padding-top: 0vh;
}

#s2container {
  padding-top: 40vh;
}



.section3 {
  width: 100%;
  height: auto;
  /* min-height:30px; */
  background-color: rgb(133, 0, 0);

  /* background-size: cover; */
  text-align: left;
  color: black;

  /* TODO: Herausfinden, warum die unteren zwei Zeilen dafür sorgen, dass section3 jetzt die richtige Höhe hat */



  display: flex;
  flex-direction: column;
  /* min-height: 10vh; */
  
/*   display: flex;
  flex: 1; */
}


@media (min-width: 900px) {
  #Schritte {
    width: 90%;
    height: 100%;
    margin-left: 5%;
    margin-right: 50%;
    background-color: rgb(228, 95, 6);
  }
  
  .Schrittbox {
    /* justify-content: center; */
    width: 28%;
    float:left; /* add this */
   /*  background-color: rgb(255, 145, 1); */
    margin-top: 5vh;
    margin-right: 8%;

  }
  
  #letzteSchrittbox {
    margin-right: 0%;

  }
  .SchritteÜberschrift {
    font-size: 300%;
    color: rgb(18, 0, 179);
    padding-top: 0vh;
  }
  
  .SchritteText {
    font-size: 150%;
    color: rgb(18, 0, 179);
    padding-top: 5vh;
  }
}

@media (max-width: 900px) {
  #Schritte {
    /* width: 5%; */
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 0px;
    justify-content: center;
    background-color: rgb(214, 130, 3);
  
  }
  
  .Schrittbox {
    /* justify-content: center; */
    /* width: 25%; */
    /* background-color: rgb(255, 255, 255); */
    padding-top: 5vh;
    margin-right: 8%;


  
  }
  
  
  .SchritteÜberschrift {
    font-size: 300%;
    color: white;
    padding-top: 0vh;
  }
  
  .SchritteText {
    font-size: 150%;
    color: white;
    padding-top: 0%;
  }
}
